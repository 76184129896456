import React, { useEffect, useState } from "react";
import BackgroundImage from "./background.webp";
import CatLeft from "./move-left.png";
import CatRight from "./move.png";
import CatJump from "./jump.png";
import CoinImage from "./archie.png";
import Dead from './dead.png'
import EnemyImage from "./enemy.png";
import { IntroModal } from "./modal";
import { ScoreSubmit } from "./scoreSubmit";


const RainbowGame = () => {
  const [characterX, setCharacterX] = useState(200); 
  const [characterY, setCharacterY] = useState(90); 
  const [backgroundX, setBackgroundX] = useState(0);
  const [characterImage, setCharacterImage] = useState(CatRight);
  const [coins, setCoins] = useState([]);
  const [score, setScore] = useState(0);
  const [isJumping, setIsJumping] = useState(false);
  const [velocityY, setVelocityY] = useState(0);
  const [enemies, setEnemies] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [start, setStart] = useState(false)

  const [platforms, setPlatforms] = useState([]);

  const gravity = -4; // Gravity pulling character down
  const jumpStrength = 45; // Initial upward velocity
  const groundLevel = 90; // Ground level in CSS `bottom`
  const movementSpeed = 40; // Speed of character movement
  const screenWidth = window.innerWidth; // Screen width for clamping and positioning


  // Spawn coins randomly in both directions
  const spawnCoins = () => {
    const viewportHeight = window.innerHeight;

    const newCoins = Array.from({ length: 4 }, () => ({
      x:
        Math.random() < 0.5
          ? Math.floor(Math.random() * (screenWidth - backgroundX + characterX) * -4.5) // Left direction
          : Math.floor(Math.random() * (screenWidth - backgroundX + characterX) * 4.5), // Right direction
      y: Math.floor(Math.random() * (viewportHeight - 150)) + 100, // Random height
      collected: false,
    }));

    setCoins((prevCoins) => [...prevCoins, ...newCoins]);
  };

  // Spawn enemies from random horizontal positions at the top of the screen
  const spawnEnemies = () => {
    const newEnemies = Array.from(
      { length: Math.random() < 0.5 ? 2 : 0 },
      () => ({
        x:
          Math.random() < 0.5
            ? Math.floor(Math.random() * (screenWidth - backgroundX + characterX) * -2.5) // Left direction
            : Math.floor(Math.random() * (screenWidth - backgroundX + characterX) * 2.5), // Right direction
        y: window.innerHeight, // Start from top just outside the viewport
        velocityY: Math.random() < 0.5 ? -5 : -8, // Fall down speed
        hitWall: false,
      })
    );

    setEnemies((prevEnemies) => [...prevEnemies, ...newEnemies]);
  };

  // Spawn platforms randomly throughout the map
  const spawnPlatforms = () => {
    // You can adjust the number of platforms
    const numberOfPlatforms = 100; 
    const viewportHeight = window.innerHeight;

    const newPlatforms = Array.from({ length: numberOfPlatforms }, () => ({
      x:
        Math.random() < 0.5
          ? Math.floor(Math.random() * (screenWidth - backgroundX + characterX) * -30)
          : Math.floor(Math.random() * (screenWidth - backgroundX + characterX) * 30),
      y: Math.floor(Math.random() * (viewportHeight - 200)) + 150, // Random Y above ground
      width: Math.random() < 0.8 ? 100 : 70,
      height: Math.random() < 0.5 ? 20 : 40,
    }));

    setPlatforms(newPlatforms);
  };

  const moveCharacter = (direction) => {
    if (gameOver) return;

    setCharacterX((prevX) => {
      let newX = prevX;

      if (direction === "right") {
        setCharacterImage(CatRight);
        newX = prevX + movementSpeed;

        // Scroll the background when the character moves past a threshold
        if (newX > screenWidth * 0.5) {
          setBackgroundX((prevBg) => prevBg - movementSpeed);
          return prevX; // Keep the character in place while the background scrolls
        }
      }

      if (direction === "left") {
        setCharacterImage(CatLeft);
        newX = Math.max(0, prevX - movementSpeed);

        // Scroll the background when the character moves past a threshold
        if (newX < screenWidth * 0.3) {
          setBackgroundX((prevBg) => prevBg + movementSpeed);
          return prevX; // Keep the character in place while the background scrolls
        }
      }

      return Math.min(screenWidth, newX); // Clamp character within screen bounds
    });
  };

  const jump = () => {
    if (gameOver) return;
    if (!isJumping) {
      setIsJumping(true);
      setVelocityY(jumpStrength); // Upward velocity
    }
  };

  const checkCollisions = () => {
    // Check for coin collisions
    setCoins((prevCoins) =>
      prevCoins.map((coin) => {
        if (
          !coin.collected &&
          Math.abs(characterX - (coin.x + backgroundX)) < 50 &&
          Math.abs(characterY - coin.y) < 50
        ) {
          setScore((prevScore) => prevScore + 10);
          return { ...coin, collected: true };
        }
        return coin;
      })
    );

    // Check for enemy collisions with character
    for (let enemy of enemies) {
      if (
        Math.abs(characterX - (enemy.x + backgroundX)) < 50 &&
        Math.abs(characterY - enemy.y) < 50
      ) {
        // Collision detected - end the game
        setGameOver(true);
        setCharacterImage(Dead);
        window.alert("Game Over!");
        break;
      }
    }
  };

  const handleKeyDown = (event) => {
    if (gameOver) return;

    if (event.key === "ArrowRight") moveCharacter("right");
    if (event.key === "ArrowLeft") moveCharacter("left");
    if (event.key === "ArrowUp") jump();
  };

  const handleKeyUp = () => {
    if (gameOver) return;
    setCharacterImage(CoinImage); // Reset character image to idle
  };

  // Check platform collision for character
  const handleCharacterPlatformCollision = (newY) => {
    if (newY < characterY) {
      // Character is moving down
      for (let platform of platforms) {
        const platformTop = platform.y;
        const platformLeft = platform.x + backgroundX;
        const platformRight = platformLeft + platform.width;

        // Check horizontal overlap
        if (characterX + 50 > platformLeft && characterX < platformRight) {
          // Check if character would land on this platform
          if (characterY >= platformTop && newY <= platformTop) {
            // Land on platform
            return platformTop;
          }
        }
      }
    }
    return newY;
  };

  // Check platform collision for enemies
  const handleEnemyPlatformCollision = (enemy) => {
    if (enemy.velocityY < 0) {
      // Enemy is moving down
      for (let platform of platforms) {
        const platformTop = platform.y;
        const platformLeft = platform.x + backgroundX;
        const platformRight = platformLeft + platform.width;

        // Check horizontal overlap
        if (enemy.x + backgroundX + 50 > platformLeft && enemy.x + backgroundX < platformRight) {
          // Check if enemy would land on this platform
          const newEnemyY = enemy.y + enemy.velocityY;
          if (enemy.y >= platformTop && newEnemyY <= platformTop) {
            // Land on platform
            return { ...enemy, y: platformTop, velocityY: 0, hitWall: true };
          }
        }
      }
    }
    return { ...enemy };
  };

  useEffect(() => {

    if (!start) return
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    spawnCoins(); 
    spawnCoins(); 
    spawnCoins(); 
    spawnCoins(); 
    spawnCoins(); 

    spawnEnemies(); 
    spawnEnemies(); 
    spawnEnemies(); 
    spawnEnemies(); 
    spawnEnemies(); 
    spawnEnemies();

    spawnPlatforms(); // Spawn platforms at the start of the game

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    if (gameOver) return;
    if (!start) return

    const gameLoop = setInterval(() => {
      // Apply gravity to character
      let newVelocityY = velocityY;
      let newCharacterY = characterY;

      if (characterY > groundLevel || velocityY > 0) {
        newVelocityY += gravity;
        newCharacterY = Math.max(groundLevel, characterY + newVelocityY);

        // Check if character lands on a platform
        newCharacterY = handleCharacterPlatformCollision(newCharacterY);

        // If character is on ground or a platform and no longer above it
        if (newCharacterY === groundLevel || platforms.some(p => p.y === newCharacterY)) {
          newVelocityY = 0;
          setIsJumping(false);
        }
      }

      setVelocityY(newVelocityY);
      setCharacterY(newCharacterY);

      // Update enemies positions
      setEnemies((prevEnemies) =>
        prevEnemies
          .map((enemy) => {
            const newEnemy = { ...enemy, y: enemy.y + enemy.velocityY };
            // Apply platform collision logic to enemies
            const platformAdjustedEnemy = handleEnemyPlatformCollision(newEnemy);

            // If the enemy didn't land on a platform, apply gravity
            if (platformAdjustedEnemy.velocityY === enemy.velocityY) {
              // If still moving down
              if (platformAdjustedEnemy.y > -50) {
                // Enemy keeps falling
                platformAdjustedEnemy.y += 0; // Already applied gravity aboveu
              }
            }
            return platformAdjustedEnemy;
          })
          // Remove enemies that have fallen off the bottom of the screen
          .filter((enemy) => enemy.y > -50)
          .filter((enemy) => enemy.hitWall !== true)
      );

      // Check for collisions (character with coins/enemies)
      checkCollisions();
      spawnEnemies();
    }, 50);

    return () => clearInterval(gameLoop);
  }, [velocityY, characterY, enemies, gameOver, platforms]);

  useEffect(() => {
    if (gameOver) return;
    spawnCoins(); 
    if (Math.random() < 0.3) {
      spawnEnemies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score]);

  return (
    <><IntroModal modalToggle={true} onClick={()=> setStart(true)}/>
    {gameOver && <ScoreSubmit modalToggle={true} score={score}/>}
    <div
          style={{
              position: "relative",
              width: "100vw",
              height: "100vh",
              overflow: "hidden",
              backgroundImage: `url(${BackgroundImage})`,
              backgroundSize: "cover",
              backgroundPositionX: `${backgroundX}px`,
              transition: "background-position 0.1s linear",
          }}
      >
          {/* Character */}
          <div
              style={{
                  position: "absolute",
                  bottom: `${characterY}px`,
                  left: `${characterX}px`,
                  width: "100px",
                  height: "100px",
                  backgroundImage: `url(${gameOver ? Dead : isJumping ? CatJump : characterImage})`,
                  backgroundSize: "cover",
              }} />

          {/* Coins */}
          {coins.map(
              (coin, index) => !coin.collected && (
                  <div
                      key={index}
                      style={{
                          position: "absolute",
                          bottom: `${coin.y}px`,
                          left: `${coin.x + backgroundX}px`,
                          width: "50px",
                          height: "50px",
                          backgroundImage: `url(${CoinImage})`,
                          backgroundSize: "cover",
                      }} />
              )
          )}

          {/* Enemies */}
          {enemies.map((enemy, index) => (
              <div
                  key={index}
                  style={{
                      position: "absolute",
                      bottom: `${enemy.y}px`,
                      left: `${enemy.x + backgroundX}px`,
                      width: "50px",
                      height: "50px",
                      backgroundImage: `url(${EnemyImage})`,
                      backgroundSize: "cover",
                  }} />
          ))}

          {/* Platforms */}
          {platforms.map((platform, index) => (
              <div
                  key={index}
                  style={{
                      position: "absolute",
                      bottom: `${platform.y}px`,
                      left: `${platform.x + backgroundX}px`,
                      width: `${platform.width}px`,
                      height: `${platform.height}px`,
                      backgroundColor: "brown",
                      border: "2px solid #654321"
                  }} />
          ))}

          {/* Score */}
          <div
              style={{
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "white",
              }}
          >
              Score: {score}
          </div>

          {/* Controls (disabled if gameOver) */}
          {!gameOver && (
              <div
                  style={{
                      position: "absolute",
                      bottom: "20px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      display: "flex",
                  }}
              >
                  <button
                      onMouseDown={() => moveCharacter("left")}
                      style={{ margin: "5px", padding: "10px 20px", fontSize: "16px" }}
                  >
                      Left
                  </button>
                  <button
                      onMouseDown={jump}
                      style={{ margin: "5px", padding: "10px 20px", fontSize: "16px" }}
                  >
                      Jump
                  </button>
                  <button
                      onMouseDown={() => moveCharacter("right")}
                      style={{ margin: "5px", padding: "10px 20px", fontSize: "16px" }}
                  >
                      Right
                  </button>
              </div>
          )}
      </div></>
  );
};

export default RainbowGame;
