import './Secret.css';
import SoleraLogo from '../files/soleraLogo.png';
import { useEffect, useState } from 'react';


import { Timestamp } from 'firebase/firestore';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import dayjs from 'dayjs';
import fireapp from '../firebaseConfig';
import { initializeApp } from 'firebase/app';
import { getDocument, updateDocument, devUtil } from './component/utils';
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

initializeApp(fireapp);

const db = getFirestore();
// var firebase = require('firebase');
// var firebaseui = require('firebaseui');
// // Initialize the FirebaseUI Widget using Firebase.
// var ui = new firebaseui.auth.AuthUI(firebase.auth());
// ui.start('#firebaseui-auth-container', {
//     signInOptions: [
//       {
//         provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
//         requireDisplayName: false
//       }
//     ]
//   });
  

function convertToFirebaseTimestamp(inputDate) {
    const jsDate = inputDate instanceof Date ? inputDate : inputDate.toDate(); // Convert dayjs to JS Date
    return Timestamp.fromDate(jsDate);
}

const Parking = () => {
    const [freeSpots, setFreeSpots] = useState(0);
    const [freeSpotList, setFreeSpotList] = useState([]);
    const [licensePlate, setLicensePlate] = useState('');
    const [unitNumber, setUnitNumber] = useState('');
    const [timeIn, setTimeIn] = useState(null);
    const [timeOut, setTimeOut] = useState(null);

    const spotList = ['spot2', 'spot4']; // Example list of spots

    const getName = (spotId) => {

        switch (spotId) {
            case 'spot2':
                return 'Spot 2'
            case 'spot4':
                return 'Spot 4'
        }
       
    }

    useEffect(() => {
        async function fetchAndUpdateFreeSpots() {
            let updatedFreeSpotList = [];
            
            for (const spot of spotList) {
                    // devUtil(spot)

                const spotData = await getDocument(spot);

                if (spotData) {
                    const now = Timestamp.now();
                    const activeReservations = (spotData.reservations || []).filter(
                        (reservation) => reservation.from <= now && reservation.to >= now
                    );

                    if (activeReservations.length > 0) {
                        const activeReservation = activeReservations[0];
                        spotData.open = false;
                        spotData.currentLicensePlate = activeReservation.licensePlate;
                        spotData.currentTimeIn = activeReservation.from;
                        spotData.currentTimeOut = activeReservation.to;
                        spotData.unitNumber = activeReservation.unitNumber
                    } else {
                        spotData.open = true;
                        spotData.currentLicensePlate = null;
                        spotData.currentTimeIn = null;
                        spotData.currentTimeOut = null;
                        spotData.unitNumber = null;
                    }

                    await updateDocument(spot, spotData);

                    if (spotData.open) {
                        updatedFreeSpotList.push(spot);
                    }
                }
            }

            setFreeSpotList(updatedFreeSpotList);
            console.log(updatedFreeSpotList)
            setFreeSpots(updatedFreeSpotList.length);
        }

        fetchAndUpdateFreeSpots();
    }, []);

    async function handleFormSubmit(event) {
        event.preventDefault();

        if (!timeIn || !timeOut || !licensePlate || !unitNumber) {
            alert('All fields are required.');
            return;
        }

        if (dayjs(timeIn).isAfter(dayjs(timeOut))) {
            alert('Time In must be earlier than Time Out.');
            return;
        }

        if (!/^\d+$/.test(unitNumber)) {
            alert('Unit Number must be digits only (ie. 817)');
            return;
        }

        const duration = dayjs(timeOut).diff(dayjs(timeIn), 'day');
        if (duration > 2) {
            alert('Reservations > 2 days require authorization. Stop by the front desk for booking.');
            return;
        }

        const newReservation = {
            licensePlate,
            unitNumber,
            from: convertToFirebaseTimestamp(timeIn),
            to: convertToFirebaseTimestamp(timeOut),
        };

        let soonestSuggestion = null; // To store the soonest available suggestion across all spots

        for (const spot of spotList) {
            const spotData = await getDocument(spot);

            if (spotData) {
                const reservations = spotData.reservations || [];
                const newFromMillis = newReservation.from.toMillis();
                const newToMillis = newReservation.to.toMillis();

                // Sort reservations by start time
                reservations.sort((a, b) => a.from.toMillis() - b.from.toMillis());

                // Check for overlap with existing reservations
                const hasConflict = reservations.some(reservation =>
                    (newFromMillis < reservation.to.toMillis() && newToMillis > reservation.from.toMillis())
                );

                if (hasConflict) {
                    // If conflict exists, find the soonest available time
                    for (let i = 0; i < reservations.length; i++) {
                        const reservation = reservations[i];
                        const reservationEndMillis = reservation.to.toMillis();

                        // If the requested start time conflicts, suggest the time after the current reservation ends
                        if (newFromMillis <= reservation.to.toMillis() && newToMillis >= reservation.from.toMillis()) {
                            const suggestionTime = dayjs(reservationEndMillis).format('YYYY-MM-DD HH:mm');

                            // Update the soonest suggestion if this is earlier than any found before
                            if (!soonestSuggestion || reservationEndMillis < soonestSuggestion) {
                                soonestSuggestion = dayjs(reservationEndMillis);
                            }

                            break; // Exit the loop once the first suggestion is found
                        }
                    }

                    // If no conflict is found, suggest the available time after the last reservation
                    if (!soonestSuggestion && reservations.length > 0) {
                        const lastReservationEndMillis = reservations[reservations.length - 1].to.toMillis();
                        soonestSuggestion = dayjs(lastReservationEndMillis);
                    }

                    continue;
                }

                // If no conflicts, create the reservation
                spotData.reservations = spotData.reservations || [];
                spotData.reservations.push(newReservation);
                spotData.open = false;
                spotData.currentLicensePlate = licensePlate;
                spotData.currentTimeIn = newReservation.from;
                spotData.unitNumber = unitNumber;
                spotData.currentTimeOut = newReservation.to;

                await updateDocument(spot, spotData);
                alert(`Reservation successful! Spot assigned: ${getName(spot)}`);

                // Update Gauge and clear fields
                window.location.reload();
                return;
            }
        }

        // If a suggestion was found, alert the user
        if (soonestSuggestion) {
            alert(`Reservation conflict detected. Suggestion for soonest "from" availbility: ${soonestSuggestion.format('YYYY-MM-DD HH:mm')}`);
        } else {
            alert("No available spots or suggestions.");
        }

        alert('No available spots.');
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="login-root">
                <div className="box-root flex-flex flex-direction--column" style={{ minHeight: '100vh', flexGrow: 1 }}>
                    <div className="loginbackground box-background--white">
                        <div className="loginbackground-gridContainer">
                            <div className="box-root padding-top--24 flex-flex flex-direction--column" style={{ flexGrow: 1, zIndex: 9 }}>
                                <div className="box-root padding-bottom--24 flex-flex flex-justifyContent--center flex-direction--column" style={{ alignItems: 'center' }}>
                                    <h1>Solera Guest Parking</h1>
                                    <h3>Current Capacity</h3>
                                    {/* <Gauge width={100} height={100} value={freeSpots} /> */}
                                    <Gauge
                                        width={100} height={100}
                                        value={ (2 - freeSpots) / 2 * 100}
                                        startAngle={-110}
                                        endAngle={110}
                                        sx={{
                                            [`& .${gaugeClasses.valueText}`]: {
                                                fontSize: 20,
                                                transform: 'translate(0px, 0px)',
                                            },
                                        }}
                                        text={
                                            ({ value }) => `${2-freeSpots} / ${2}`
                                        }
                                    />
                                </div>
                                <div className="formbg-outer">
                                    <div className="formbg">
                                        <div className="formbg-inner padding-horizontal--48">
                                            <span className="padding-bottom--15">Reserve a Spot</span>
                                            <form id={'form'} onSubmit={handleFormSubmit}>
                                                <div className="field padding-bottom--24">
                                                    <label htmlFor="plate">License Plate</label>
                                                    <input
                                                        type="text"
                                                        name="plate"
                                                        value={licensePlate}
                                                        onChange={(e) => setLicensePlate(e.target.value)}
                                                    />
                                                </div>
                                                <div className="field padding-bottom--24">
                                                    <label htmlFor="unit">Unit Number</label>
                                                    <input
                                                        type="text"
                                                        name="unit"
                                                        value={unitNumber}
                                                        onChange={(e) => setUnitNumber(e.target.value)}
                                                    />
                                                </div>
                                                <div className="padding-bottom--24">
                                                    <DateTimePicker
                                                        label="Time In"
                                                        value={timeIn}
                                                        onChange={setTimeIn}
                                                    />
                                                </div>
                                                <div className="padding-bottom--24">
                                                    <DateTimePicker
                                                        label="Time Out"
                                                        value={timeOut}
                                                        onChange={setTimeOut}
                                                    />
                                                </div>
                                                <div className="field padding-bottom--24">
                                                    <input type="submit" value="Reserve Spot" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LocalizationProvider>
    );
};

export default Parking;
