import "../components/modal.css";
import React, { useState } from "react";

import { initializeApp } from "firebase/app";
import { doc, setDoc, getDocs, collection } from "firebase/firestore"; 
import { getFirestore } from "firebase/firestore";
import { orderBy } from "lodash";

const firebaseConfig = {
    apiKey: "AIzaSyCxEidV_q-q_H-gMSkZ2YkUh4la4zRob6g",
    authDomain: "josie-trivia.firebaseapp.com",
    projectId: "josie-trivia",
    storageBucket: "josie-trivia.appspot.com",
    messagingSenderId: "16652469066",
    appId: "1:16652469066:web:9035a48e8f7855eb9e77b3",
    measurementId: "G-CRMVWN68XC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, 'game');

export const ScoreSubmit = ({ modalToggle, score }) => {
  const [showModal, setShowModal] = useState(modalToggle);
  const [leaderboardScores, setLeaderboardScores] = useState([]);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const leadboardScoreGrabber = async () => {
    const db = getFirestore(app);
    try {
      const querySnapshot = await getDocs(collection(db, "leaderboard"));
      const temp = querySnapshot.docs.map((doc) => doc.data());
      setLeaderboardScores(temp);
    } catch (error) {
      console.error("Error fetching leaderboard: ", error);
    }
  };

  const writeUserData = async (name, email, score) => {
    const db = getFirestore(app);
    try {
      await setDoc(doc(db, "leaderboard", `${name}-${Date.now()}`), {
        name,
        email,
        score,
      });
      console.log("Score submitted successfully");
    } catch (error) {
      console.error("Error submitting score: ", error);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!name || !email) {
      alert("Please fill out all fields.");
      return;
    }
    writeUserData(name, email, score);
    setName('');
    setEmail('');
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Submit Score</h4>
            </div>
            <div className="modal-body">
              <form id="form" onSubmit={handleFormSubmit}>
                <div>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <label>Your score: {score}</label>
                <div>
                  <input type="submit" value="Submit Score" />
                </div>
              </form>
            </div>
            <div className="modal-footer"></div>
            {showLeaderboard &&
              orderBy(leaderboardScores, "score", "desc").map((player, index) => (
                <div key={index}>
                  {player.name} with a score of {player.score}
                </div>
              ))}
            <button
              className="modal-close"
              onClick={() => {
                leadboardScoreGrabber();
                setShowLeaderboard(!showLeaderboard);
              }}
            >
              See Leaderboard
            </button>
            <button className="modal-close" onClick={() => window.location.reload()}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};
