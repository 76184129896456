import "../components/modal.css";
import React, { useState } from "react";
export const IntroModal = ({modalToggle, onClick}) => {
  const [showModal, setShowModal] = useState(modalToggle);

    const handleModal = () => {
        setShowModal(false);
        onClick();
    }

  return ( <>
  {showModal ?
   <div className="modal">
   <div className="modal-content">
       <div className="modal-header">
           <h4 className="modal-title">Hello Soldier</h4>
       </div>
       <div className="modal-body">
           <p>
                You've traveled to NYC in the year 2042. The city is being attacked by a big looking turtle. crud.
           </p>
           <p>
                Gather as many Archies as you can and avoid getting hit by phill
           </p>
           <p>
                warning this game is mega not good. made in 4 hours. gg wp
           </p>

           <p>
                Top score by end of Dec 13th gets their own personal page @ dillonhowell.com/YOUR_CUSTOM_URL ( if you want )
          </p>
       </div>
       <div className="modal-footer">
       </div>
       <button className="modal-close" onClick={handleModal}>Close</button>
   </div>
</div>

    : null}
    </>)
}