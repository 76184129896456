import '../Secret.css';
import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import fireapp from '../../firebaseConfig';
import { initializeApp } from 'firebase/app';
import moment from 'moment';
import Timeline from 'react-calendar-timeline';
import * as XLSX from 'xlsx'; // Import xlsx
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


initializeApp(fireapp);

const db = getFirestore();

const ParkingAdming = () => {
    const [rows, setRows] = useState([]);
    const [timelineItems, setTimelineItems] = useState([]); // State for timeline items
    const spotList = ['spot2', 'spot4']; // Example list of spots
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const columns = [
        { field: 'spotName', headerName: 'Spot Number', width: 130 },
        { field: 'status', headerName: 'Currently Available', width: 150 },
        { field: 'in', headerName: 'Time In', width: 130 },
        { field: 'out', headerName: 'Time Out', width: 130 },
        { field: 'unitNumber', headerName: 'Unit Number', width: 130 },
        { field: 'reservations', headerName: 'Reservation History', width: 250, renderCell: (params) => <Button style={{ backgroundColor: 'orange', color: 'white', fontWeight: 'bold' }} onClick={() => handleExport(params.row)}>Download Reservations</Button> }
    ];
    const handleItemSelect = (itemId) => {
        const item = timelineItems.find((i) => i.id === itemId);
        console.log(item, "HERE")
        if (item) {
            setSelectedItem(item);
            handleOpen();
        }
    };


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {
        async function fetchAndUpdateData() {
            const spotDatas = [];
            const timelineData = [];
            for (const spot of spotList) {
                const spotData = await getDocument(spot);

                // Prepare row data for DataGrid
                let rowItem = {
                    id: spot,
                    spotName: spot,
                    status: spotData.open ? 'Available' : 'Occupied',
                    in: spotData.currentTimeIn ? spotData.currentTimeIn.toDate().toLocaleString() : 'Not Applicable',
                    out: spotData.currentTimeOut ? spotData.currentTimeOut.toDate().toLocaleString() : 'Not Applicable',
                    unitNumber: spotData.unitNumber ? spotData.unitNumber : 'Not Applicable',
                    reservations: spotData.reservations ? spotData.reservations : 'No previous reservations',
                };
                spotDatas.push(rowItem);

                // Transform reservations into Timeline items
                if (spotData.reservations) {
                    spotData.reservations.forEach((reservation, index) => {
                        timelineData.push({
                            id: `${spot}-${index}`, // Unique ID for each reservation
                            group: spot, // Use spot as group
                            title: `Plate: ${reservation.licensePlate} | Unit: ${reservation.unitNumber}`,
                            start_time: moment(reservation.from.toDate()), // Convert Timestamp to moment
                            end_time: moment(reservation.to.toDate()), // Convert Timestamp to moment
                        });
                    });
                }
            }
            setRows(spotDatas);
            setTimelineItems(timelineData); // Update the Timeline items
        }

        fetchAndUpdateData();
    }, []);

    // Fetch document from Firestore
    async function getDocument(documentId) {
        const docRef = doc(db, 'spots', documentId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    }

    // Handle export to Excel
    const handleExport = (row) => {
        const wb = XLSX.utils.book_new();
        const updatedItems = row.reservations.map(item => ({
            ...item,
            from: item.from.toDate().toLocaleString(),
            to: item.to.toDate().toLocaleString(),
        }));
        const ws = XLSX.utils.json_to_sheet(updatedItems);
        XLSX.utils.book_append_sheet(wb, ws, `Reservation History - ${row.spotName}`);
        XLSX.writeFile(wb, `${row.spotName}_reservations.xlsx`);
    };
    const getName = (spotId) => {

        switch (spotId) {
            case 'spot2':
                return 'Spot 2'
            case 'spot4':
                return 'Spot 4'
        }
       
    }
    return (
        <div className='page-container' style={{ backgroundColor: 'white' }}>
            <Box sx={{ width: '95%' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Reservation Info
                            </Typography>
                            {selectedItem &&
                                <>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Time in: {selectedItem.start_time.format('MMMM Do YYYY, h:mm:ss a')}
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                                        Time out: {selectedItem.end_time.format('MMMM Do YYYY, h:mm:ss a')}
                                    </Typography>

                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                                        User Info: {selectedItem.title}
                                    </Typography>
                                </>

                            }

                        </Box>
                    </Modal>
                    <Timeline
                        className='react-calendar-timeline'
                        lineHeight='80'
                        // itemTouchSendsClick={true}
                        groups={spotList.map((spot, index) => ({ id: spot, title: `${getName(spot)}` }))}
                        items={timelineItems}
                        defaultTimeStart={moment().add(-12, 'hour')}
                        defaultTimeEnd={moment().add(12, 'hour')}
                        onItemSelect={(itemId) => handleItemSelect(itemId)}
                    />
                    <DataGrid
                        pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                        sx={{
                            boxShadow: 2,
                            fontWeight: 'bold',
                            border: 2,
                            borderColor: 'white',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'white',
                            },
                        }}
                        rows={rows}
                        columns={columns}
                    />
                </div>
            </Box>
        </div>
    );
};

export default ParkingAdming;
