import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import fireapp from '../../firebaseConfig';
import { initializeApp } from 'firebase/app';

initializeApp(fireapp);

const db = getFirestore();

export async function getDocument(documentId) {
    const docRef = doc(db, 'spots', documentId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
}

export async function updateDocument(documentId, data) {
    const docRef = doc(db, 'spots', documentId);
    await updateDoc(docRef, data);
}


export const devUtil = (spot) => {

    const clearSpot = {
        unitNumber: null,
        currentLicensePlate: null,
        currentTimeIn: null,
        currentTimeOut: null,
        open: true,
        reservations: []
    }

    updateDocument(spot, clearSpot)
}