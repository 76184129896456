import { useState, useEffect } from "react"
import "./Secret.css"
import emailjs from 'emailjs-com';

import guac from '../files/loading.png'

const Secret = () => {
    const [step, setStep] = useState(-1)
    const [longIn, setLogIn] = useState(false)
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [no, setNo] = useState('No')
    const [count, setcount] = useState(0)

    const Steps = ({step}) => 
    {
        switch(step) {
            case -1: return <></>
            case 0: 
                return <div className="info">
                    <div className='typewriter'>
                    <h1>{'To: Josie'}</h1>
                    <h2>{'From: Dillon'}</h2>
                    </div>
                    </div>
            case 1: 
                return    <div className="info">
                <div className='typewriter'>
                <h1>{'I am going to guess'}</h1>
                <h2>{'you might be thinking..'}</h2>
                </div>
                </div>
            case 2: 
                return    <div className="info">
                <div className='typewriter'>
                <h1>{'Why did he make this?'}</h1>
                </div>
                </div>
            case 3: 
            return    <div className="info">
            <div className='typewriter'>
            <h1>{'What is he trying to'}</h1>
            <h2>{'get out of this?'}</h2>
            </div>
            </div>
           case 4: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'or maybe about canes..'}</h1>
           <h2>{'if you\'re hungry'}</h2>
           </div>
           </div>
           case 5: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'I remember you telling me'}</h1>
           <h2>{'something your mom said'}</h2>
           </div>
           </div>
           case 6: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'that these sites are like'}</h1>
           <h2>{'old-school love letters'}</h2>
           </div>
           </div>
           case 7: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'and how you liked them-'}</h1>
           <h2>{'So I made this last night'}</h2>
           </div>
           </div>
           case 8: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'Which pivots to'}</h1>
           <h2>{'that second question'}</h2>
           </div>
           </div>
           case 9: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'I wanted to ask you'}</h1>
           </div>
           </div>
           case 10: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'In the nerdiest way possible'}</h1>
           </div>
           </div>
           case 11: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'Out to drinks at'}</h1>
           <h2>{'the halloween bar downtown'}</h2>
           </div>
           </div>
           case 12: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'and hangout like last time'}</h1>
           </div>
           </div>
           case 13: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'No costume required'}</h1>
           <h2>{'as this is scary enough'}</h2>
           </div>
           </div>
           case 14: 
           return    <div className="info">
           <div className='typewriter'>
           <h2>{'ha ha'}</h2>
           </div>
           </div>
           case 15: 
           return    <div className="info">
           <div className='typewriter'>
           <h1>{'Anyway,'}</h1>
           <h2>{'here is my invite'}</h2>
           </div>
           </div>
           case 16:
            return <div style={{display:'flex', flexDirection:'column', justifyContent: 'center'}} className="info">
                    <div className='typewriter'>
                        <h1>{'Get really spooky drinks'}</h1>
                        <h2>{'sometime this week?'}</h2>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-evenly', alignContent:'center'}}>
                    <div className='decideButton' onClick={() => {
                        emailjs.send(
                            'service_h7s1h2d', // Your EmailJS service ID
                            'template_ahgpnrs', // Your EmailJS template ID
                            {
                              from_email: 'secret',
                              to_email: 'dillonleehowell@gmail.com', // Replace this with your personal email
                              message: 'yes',
                            },
                            'dSTWvsUdjwLVOjdWa' // Your EmailJS user ID
                          )&& setStep(17)
                    }}>Yes</div> 
                        <div className='decideButton' onClick={()=>setcount(count+1)}>{no}</div>
                    </div>
                </div>
case 17:
            return <div style={{display:'flex', flexDirection:'column', justifyContent: 'center'}} className="info">
            <div className='typewriter'>
                <h1>{'Answer submitted'}</h1>
                <h2>Be in touch soon</h2>
            </div>
        </div>
        }
    }
        
    useEffect(() => {
        if (loading) {
          setTimeout(() => {
          setLoading(false);
        }, 2000);
        }
      }, [loading]);

    useEffect(() => {
            switch (count){
                case 1: setNo('Are you sure?')
                break
                case 2: setNo('Are you REALLY sure?')
                break
                case 3: setNo('ok, last chance')
                break
                case 4: setNo('Yes, but click the other one')
                break
            }
        }, [count])
    

    const checkPass = (pass) => pass === 'novacat' && (setLogIn(true), setStep(0), setLoading(true) 
    , emailjs.send(
        'service_h7s1h2d', // Your EmailJS service ID
        'template_ahgpnrs', // Your EmailJS template ID
        {
          from_email: 'secret',
          to_email: 'dillonleehowell@gmail.com', // Replace this with your personal email
          message: 'password entered',
        },
        'dSTWvsUdjwLVOjdWa' // Your EmailJS user ID
      )
      )


    return (
        <div style={{fontFamily: "Fira Code, monospace", color: 'green', height: '100vh', width: '100vw', display:'flex', justifyContent: 'center', alignItems:'center', flexDirection:'column'}}>
        {!longIn && <div className ='password'>
            <h1 className='passwordTitle'>PASSWORD</h1>
            <input className='inputField' type='text' placeholder="Enter Password" onChange={e => setPassword(e.target.value)} onKeyDown={e => e.key === 'Enter' && checkPass(password)}></input>
            <div className='submitButton' onClick={()=>checkPass(password)}>Submit</div>
       </div>}
       {loading && <div className ='password'>
        <img className="guac" src={guac}></img>
       </div>

       }
        <div className="outlineBox">
        <Steps step={step}/>
        { step >=0 && step < 16 && <div className="nextButton" onClick={ () => step <16 && setStep(step + 1)}>
            Continue
        </div>}

        </div>
    </div>
    )

}

export default Secret