// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBUP1l0B-_kAQm5yAywCSlAXHDdL1_RMMg",
  authDomain: "soleraparking.firebaseapp.com",
  projectId: "soleraparking",
  storageBucket: "soleraparking.firebasestorage.app",
  messagingSenderId: "468548714523",
  appId: "1:468548714523:web:6af9a896e70effa642f8be"
};

// Initialize Firebase
// const cog = initializeApp(firebaseConfig)

export default firebaseConfig